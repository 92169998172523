<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col">
        <p>
          Select the time period to display
          <date-range-picker v-if="loaded"
                  ref="picker"
                  v-model="dateRange"
                  :locale-data="{ firstDay: 1, format:'dd/mm/yyyy' }"
                  :minDate="minDate" :maxDate="maxDate"
                  :ranges="false"
                  :linked-calendars="false"
          >
          </date-range-picker>
        </p>
      </div>
    </div>
    <div class="col col-lg-8">
      <line-chart :width="900" :height="600"
        v-if="loaded"
        :chartData="chartData"
        :options="options"/>
    </div>

  </div>
</template>


<script>

const moment = require("moment");

import store from "@/store";
import Vue from "vue";
import LineChart from "./LineChart.vue";
import DateRangePicker from 'vue2-daterange-picker'

// We need to import the CSS manually (in case we want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


function formatData(input) {
  let data = [];
  input["data"].forEach(
    element => {
      data.push({t: moment(element[0], "DD/MM/YYYY"), y: element[1]});
  });
  return {
    company: input["company"],
    data: data
  };
}


async function getData() {
  const data = await Vue.axios.get("/stocks/")
  .then((response) => response.data);
  return data;
}


function getChartdata(data) {
  /* We construct the dataset from the raw data, making sure
    we pick a new colour for each */
  const palette = [
    "pink", "red", "orange", "yellow", "green", "blue",
    "indigo", "purple", "brown", "cyan"
  ]
  let datasets = [];
  let maxDate = new Date(-8640000000000000);
  let minDate = new Date(8640000000000000);
  data.forEach(
    (entry, index) => {
      const color = palette[index % palette.length]
      let formattedData = formatData(entry);
      let minDateInData = formattedData.data[0].t
      let maxDateInData = formattedData.data[formattedData.data.length - 1].t
      if (minDateInData < minDate) {
        minDate = minDateInData.toDate();
      }
      if (maxDateInData > maxDate) {
        maxDate = maxDateInData.toDate();
      }
      datasets.push(
        {
          label: formattedData.company,
          data: formattedData.data,
          borderColor: color,
          backgroundColor: color,
          type: "line",
          pointRadius: 0,
          fill: false,
          lineTension: 0,
          borderWidth: 1.5,
        }
      );
    }
  );
  return {datasets, minDate, maxDate};
}


function getDefaultOptions(){
  return {
    responsive: false,
    maintainaspectratio: false,
    animation: {
      duration: 0
    },
    legend: {
      position: "right",
    },
    scales: {
      xAxes: [{
        type: "time",
        time: {
          unit: "day"
        },
        distribution: "linear",
        offset: true,
        ticks: {
          major: {
            enabled: true,
            fontStyle: "bold"
          },
          min: store.getters.getDateRange.startDate,
          max: store.getters.getDateRange.endDate,
        },
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false
        },
        scaleLabel: {
          display: true,
          labelString: "Closing price"
        }
      }]
    }
  };
}


export default {
  name: "StockChart",
  components: { LineChart, DateRangePicker },
  data: () => ({
    loaded: false,
    chartData: null,
    options: null,
    dateRange: {
      startDate: store.getters.getDateRange.startDate,
      endDate: store.getters.getDateRange.endDate,
    },
    minDate: null,
    maxDate: null,
  }),
  watch: {
    dateRange (val) {
      store.dispatch("updateDateRange", {val}).then(() => {
        this.options = getDefaultOptions();
      })
    }
  },
  async mounted () {
    this.loaded = false
    try {
      const data = await getData();
      const chartdata = getChartdata(data);
      this.minDate = chartdata.minDate;
      this.maxDate = chartdata.maxDate;
      this.chartData = chartdata;
      this.options = getDefaultOptions();
      this.loaded = true;
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style>
.time-period-custom{
  padding-top: 10px;
}
</style>
