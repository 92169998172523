<script>

import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  // Despite the use of the mixin, it seems necessary
  // to define explicilty a watcher to get reactivity
  // on the Options prop.
  // https://github.com/apertureless/vue-chartjs/issues/116
  watch: {
   'options':  function (newOptions) {
       this.$data._chart.destroy();
       this.renderChart(this.chartData, newOptions);

       //this.$data._char.dataset._meta[0].hidden = true;
     },
   },
  mounted () {
    this.renderChart(this.chartData, this.options);
  }
}
</script>
