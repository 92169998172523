<template>
  <div id="chart">
    <h3>Stock price for subscribed companies</h3>
    <stock-chart></stock-chart>
  </div>
</template>

<script>
import StockChart from '@/components/StockChart'

export default {
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push("/");
    }
  },
  components: {
    StockChart
  }
}
</script>

<style lang="scss">
#chart {
    margin: 30px auto;
    h3 {
      padding-top: 1em;
      padding-bottom: 1em;
      font-size: 1.5em
    }
}
</style>
